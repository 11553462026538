<template>
  <div>
    <div class="studentlist">
      <div
        class="
          operationControl
          flexdc
          operationControlCurr
          operationControlWidth
        "
        style="align-items: flex-start"
      >
        <div class="searchbox" style="padding-left: 0.5rem">
          <div title="姓名" class="searchboxItem ci-full">
            <span class="itemLabel">姓名:</span>
            <el-input
              v-model="userName"
              type="text"
              size="small"
              placeholder="请输入"
              clearable
            />
          </div>
          <div title="身份证号" class="searchboxItem ci-full">
            <span class="itemLabel">身份证号:</span>
            <el-input
              v-model="idcard"
              type="text"
              size="small"
              placeholder="请输入"
              clearable
            />
          </div>
          <div title="手机号" class="searchboxItem ci-full">
            <span class="itemLabel">手机号:</span>
            <el-input
              v-model="mobile"
              type="text"
              size="small"
              placeholder="请输入"
              clearable
            />
          </div>
          <div title="学习状态" class="searchboxItem ci-full">
            <span class="itemLabel">学习状态:</span>
            <el-select
              v-model="completeState"
              placeholder="请选择"
              clearable
              size="small"
            >
              <el-option
                v-for="item in complete"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              ></el-option>
            </el-select>
          </div>
          <el-button type="primary" class="bgc-bv" round @click="getData()"
            >查询</el-button
          >
        </div>
      </div>
    </div>

    <div class="framePage-scroll">
      <div class="ovy-a">
        <el-table
          ref="multipleTable"
          :data="tableData"
          :height="tableHeight"
          size="small"
          tooltip-effect="dark"
          style="width: 100%"
          :header-cell-style="tableHeader"
          stripe
        >
          <el-table-column
            label="序号"
            align="center"
            type="index"
            :index="indexMethod"
            width="80"
            fixed
          />
          <el-table-column
            label="姓名"
            align="left"
            show-overflow-tooltip
            prop="userName"
            min-width="120"
          />
          <el-table-column
            label="身份证号"
            align="left"
            show-overflow-tooltip
            prop="idcard"
            min-width="200"
          />
          <el-table-column
            label="手机号"
            align="left"
            show-overflow-tooltip
            prop="mobile"
            min-width="150"
          />
          <el-table-column
            label="入班时间"
            align="left"
            show-overflow-tooltip
            prop="createTime"
            min-width="150"
          >
            <template slot-scope="scope">
              {{ scope.row.createTime | moment }}
            </template>
          </el-table-column>
          <el-table-column
            label="学习进度"
            align="right"
            show-overflow-tooltip
            prop="createTime"
            min-width="100"
          >
            <template slot-scope="scope">
              {{ scope.row.learnProgress }}
            </template>
          </el-table-column>
          <el-table-column label="学习状态" align="left" min-Width="150">
            <template slot-scope="scope">
              <span v-if="scope.row.studyState != '40'">
                {{ $setDictionary("LEARNINGSTATE", scope.row.completeState) }}
              </span>
              <el-tooltip
                v-else
                class="item"
                effect="dark"
                :content="scope.row.notCompleteReasons"
                placement="top"
              >
                <span
                  style="
                    cursor: pointer;
                    display: flex;
                    justify-content: center;
                  "
                  >{{
                    $setDictionary("LEARNINGSTATE", scope.row.studyState)
                  }}</span
                >
              </el-tooltip>
            </template>
          </el-table-column>
          <el-table-column
            label="操作"
            align="center"
            fixed="right"
            min-width="220"
          >
            <div slot-scope="scope" class="flexcc">
              <el-button
                v-if="scope.row.auditState !== '30'"
                type="text"
                size="mini"
                style="padding: 0 5px"
                @click="Learningrecords(scope.row)"
                >学习记录</el-button
              >
              <el-button
                v-if="scope.row.auditState !== '30'"
                type="text"
                size="mini"
                style="padding: 0 5px"
                @click="Certificationrecords(scope.row)"
                >认证记录</el-button
              >
            </div>
          </el-table-column>
          <Empty slot="empty" />
        </el-table>
      </div>
    </div>
    <PageNum :apiData="apiData" @sizeChange="sizeChange" @getData="getData" />
  </div>
</template>
<script>
import Empty from "@/components/Empty.vue";
import PageNum from "@/components/PageNum.vue";
import List from "@/mixins/List";
export default {
  name: "myresourseList",
  components: {
    Empty,
    PageNum,
  },
  props: ["length"],
  mixins: [List],
  data() {
    return {
      idcard: "",
      userName: "",
      mobile: "",
      completeState: "",
      complete: [],
    };
  },
  created() {
  },
  methods: {
    init() {
      this.liveProjectId = sessionStorage.getItem("liveProjectId");
      this.getTableHeight();
      this.getcompleteList();
    },
    // 获取学习状态
    getcompleteList() {
      const studylist = this.$setDictionary("LEARNINGSTATE", "list");
      const list = [];
      for (const key in studylist) {
        list.push({
          value: key,
          label: studylist[key],
        });
      }
      this.complete = list;
    },
    //列表数据
    getData(pageNum) {
      // sessionStorage.setItem("refresh", 10);
      const params = {
        pageNum: pageNum,
        pageSize: this.pageSize,
        liveProjectId: this.liveProjectId,
      };
      if (this.userName) {
        params.userName = this.userName;
      }
      if (this.idcard) {
        params.idcard = this.idcard;
      }
      if (this.mobile) {
        params.mobile = this.mobile;
      }
      if (this.completeState) {
        params.completeState = this.completeState;
      }
      this.doFetch(
        {
          url: "/liveProject/live-project/studentPageList",
          params,
          pageNum,
        },
        true,
        3
      );
    },
    Learningrecords(row) {
      this.$router.push({
        path: "/web/liveBroadcastManagement/liveClassStudentListLearningRecords",
        query: {
          info: JSON.stringify(row),
        },
      });
    },
    //认证记录
    Certificationrecords(row) {
      this.$router.push({
        path: "/web/liveBroadcastManagement/liveClassStudentListCertificationrecords",
        query: {
          info: JSON.stringify(row),
        },
      });
    },
  },
};
</script>
<style lang="less">
// 截图
.cropper-content {
  .cropper {
    width: auto;
    height: 578px;
  }
}
.cropper_tip {
  background-color: #f1f7fd;
  padding: 15px 20px;
  h3 {
    margin-bottom: 15px;
  }
  .tip_index {
    width: 30px;
    height: 30px;
    line-height: 30px;
    text-align: center;
    background-color: #5b6be7;
    color: #fff;
    border-radius: 50%;
    margin-right: 10px;
  }
  .tip_text {
    min-width: 140px;
  }
}
//
.exportdialog {
  .upload-workers {
    height: 30px;
    .el-upload {
      height: 30px !important;
      width: 97px;
      border: none !important;
      .el-button {
        padding: 5px 0;
      }
    }
  }
}
.pic_upload {
  .el-upload {
    border: 0;
    display: block;
    max-height: 50px !important;
    text-align: left;
  }
}
.idPhoto {
  div {
    position: relative;
    display: flex;
    div {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: rgba(0, 0, 0, 0.5);
    }
  }
}
// 图片裁剪
.vueCropper_dialog {
  .el-upload {
    display: inline-block;
    border: 0;
    height: 16px !important;
  }
}
</style>
<style lang="less" scoped>
.listWrap {
  .el-icon-arrow-down {
    font-size: 12px;
    &:before {
      content: "\e6df" !important;
    }
  }
  .el-tabs {
    flex: 1;
    display: flex;
    flex-direction: column;
    .el-tabs__content {
      flex: 1;
    }
  }
}
.Classitem {
  .el-form-item__content {
    display: flex;
  }
}
.el-button.is-disabled,
.el-button.is-disabled:focus,
.el-button.is-disabled:hover {
  background-color: transparent;
}

.confirmButtonClass {
  color: #fff !important;
  background-color: #5c6be8 !important;
  border-color: #5c6be8 !important;
}
.btnBox {
  margin-left: 1.5rem;
  margin-right: 1.5rem;
}
.exportdialog {
  .el-dialog__title {
    font-size: 16px;
  }
  .export-box {
    width: 100%;
    margin-bottom: 20px;
    h1 {
      padding: 0 5px 10px;
      display: flex;
      align-items: flex-end;
      font-size: 40px;
      border-bottom: 1px dashed #ccc;
      span {
        margin-left: 20px;
        font-size: 14px;
      }
    }
    > div {
      padding-left: 30px;
    }
    .el-button.is-disabled,
    .el-button.is-disabled:focus,
    .el-button.is-disabled:hover {
      color: #fff;
      background: #c3c3c3;
      border: none;
    }
  }
}
.docsDialog {
  .el-form-item {
    margin-bottom: 0.5rem;
  }
}
.studentNum {
  margin: 20px 0 10px;
  span {
    margin: 0 10px;
  }
}
</style>